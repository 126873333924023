<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section">
				<div class="container">
					<div class="grid mb-4 card nice-shadow-6 justify-content-between align-items-center">
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
											class="p-button-sm p-button-rounded px-4" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">Find a Room</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="col comp-grid">
						<div>
							<div class="page-records" v-if="!loading">
								<div class="grid justify-content-start">
									<template v-for="(data, i) of records" :key="i">
										<div class="col-12 md:col-3">
											<router-link :to="`/rooms/details/${data.room_id}`">
											<div class="nice-shadow-6 animated zoomIn">
												<NiceImage v-ripple
													style="max-width:100%;width:100%;"
													:src="$utils.setImgUrl(data.images)" />
												<div class="font-bold py-4 text-center">{{ $utils.upper(data.room_type)}}</div>
											</div>
											</router-link>
										</div>
									</template>
								</div>
							</div>
							<!-- page loading indicator -->
							<template v-if="loading">
								<div>
									<table class="p-datatable-table w-full">
										<tbody>
											<tr v-for="n in 5" :key="n">
												<td class="text-left" style="width:50%">
													<Skeleton height="4rem" class="m-2" />
												</td>
												<td class="text-left" style="width:50%">
													<Skeleton height="4rem" class="m-2" />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</template>
							<!-- end of page loading indicator-->
							<!-- Empty record -->
							<template v-if="pageReady && !records.length">
								<div class="p-3 my-3 text-500 text-lg font-medium text-center">
									No rooms found
								</div>
							</template>
							<!-- end of empty record-->
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { reactive, toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useListPage } from '@/composables/listpage.js';
	const props = defineProps({
		primaryKey: {
			type: String,
			default: 'room_id',
		},
		pageName: {
			type: String,
			default: 'rooms',
		},
		routeName: {
			type: String,
			default: 'roomscategories',
		},
		apiPath: {
			type: String,
			default: 'rooms/categories',
		},
		paginate: {
			type: Boolean,
			default: false,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: true,
		},
		emptyRecordMsg: {
			type: String,
			default: "No rooms found",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this record?",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 15,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: '', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	const app = useApp();
	const filters = reactive({
	});
	// init list page hook
	const page = useListPage(props, filters);
	const { state, methodsProps } = page;
	const {
		totalRecords,
		recordCount,
		loading,
		selectedItems,
		pagination,
		searchText,
		pageReady
	} = toRefs(page.state);
	const {
		records,
		apiUrl,
		currentRecord,
		pageBreadCrumb,
		canLoadMore,
		finishedLoading,
		totalPages,
		recordsPosition
	} = page.computedProps;
	const {
		load,
		reload,
		loadNextPage,
		loadPreviousPage,
		exportPage,
		clearSearch,
		onSort,
		debounce,
		deleteItem,
		setCurrentRecord,
		isCurrentRecord,
		removeFilter,
		getFilterLabel,
		filterHasValue,
		importComplete
	} = page.methods;
	function getActionMenuModel(data) {
		return []
	}
	onMounted(() => {
		const pageTitle = "Room Categories";
		app.setPageTitle(props.routeName, pageTitle);
		load();
	});
</script>
<style scoped>
</style>